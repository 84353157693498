import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import HeaderMini from 'components/Header/HeaderMini';
import Container from 'components/shared/Container';

const Wrapper = styled.main`
  margin: 60px 0 90px 0;
`;

const Content = styled.article`
  max-width: 900px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.4;
  & ul,
  & ol {
    margin: 20px 0;
    & li {
      margin: 3px 0;
    }
  }
  & ul {
    list-style-type: disc;
    padding: 0 10px;
  }
  & ol {
    list-style-type: decimal;
    padding: 0 20px;
  }
  & a {
    color: ${({ theme }) => theme.colors.greenPrimary};
    font-weight: 600;
    transition: color 300ms ease-in-out;
    &:hover {
      color: ${({ theme }) => theme.colors.blueVariant};
    }
  }
  & p {
    margin: 0 0 20px 0;
    font-size: 16px;
    line-height: 1.4;
  }
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin: 40px 0 20px 0;
    font-weight: 600;
  }
  & h1 {
    font-size: 38px;
  }
  & h2 {
    font-size: 30px;
  }
  & h3 {
    font-size: 26px;
  }
  & h4 {
    font-size: 22px;
  }
  & h5 {
    font-size: 18px;
  }
  & h6 {
    font-size: 16px;
  }
`;

const PrivacyPage = ({ data }) => {
  const PAGE = data.wpPage;

  return (
    <>
      <SEO title="Polityka prywatności" />
      <HeaderMini
        center
        maxWidth="540px"
        subheading="O firmie"
        heading="Polityka prywatności"
      />
      <Wrapper>
        <Container>
          <Content
            dangerouslySetInnerHTML={{
              __html: PAGE.content,
            }}
          />
        </Container>
      </Wrapper>
    </>
  );
};

PrivacyPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryPagePolicy {
    wpPage(slug: { eq: "polityka-prywatnosci" }) {
      content
    }
  }
`;

export default PrivacyPage;
